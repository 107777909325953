import { EventNameString, getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
const app = initializeApp(config)
const analytics = getAnalytics(app)

const firebaseAnalytics = {
    logEvent: (eventName: EventNameString, eventParams: { [key: string]: any }) => {
        logEvent(analytics, eventName as any, eventParams)
    },
    setUserId: (id: string) => {
        setUserId(analytics, id)
    },
    setUserProperties: (properties: { [key: string]: any }) => {
        setUserProperties(analytics, properties)
    }
}

export default firebaseAnalytics