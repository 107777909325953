import { useMemo } from 'react'
import { UserRolesT } from '../types/enums'
import WhichStakeholder from './which-stakeholder'

export const keycloak: any = createKeycloak()
let redirectUri: string = window.location.origin

/**
 * Create a new instance of Keycloak without running init function.
 */
function createKeycloak() {
    const KeycloakClass = (window as any).Keycloak
    return new KeycloakClass(process.env.REACT_APP_KEYCLOAK_CONFIG_URL)
}

/**
 * Initialize the Keycloak object and return the authentication result.
 * This will throw an error if the keycloak.js is not loaded before this function.
 */
export function Login(): Promise<{ keycloak: any, authenticated: boolean }> {
    const resolveSuccess = (resolve: (arg0: { keycloak: any; authenticated: boolean }) => void) => {
        (window as any).keycloak = keycloak
        resolve({ keycloak, authenticated: true })
    }

    const resolveError = (resolve: (arg0: { keycloak: any; authenticated: boolean }) => void) => {
        resolve({ keycloak, authenticated: false })
    }

    return new Promise<{ keycloak: any, authenticated: boolean }>((resolve) => {
        // Preform 'check-sso' for silent check of user authentication status
        // Required to preserve correct url. E.g. manually entered url for logged in users
        keycloak
            .init({ onLoad: 'check-sso', checkLoginIframe: false })
            .success((res: boolean) => {
                if (res) {
                    resolveSuccess(resolve)
                    return
                }

                // If user is not authenticated do regular login with redirect to root of application
                // If user is admin he will be redirected to choose role screen
                keycloak
                    .login({ redirectUri: window.location.origin })
                    .success(() => resolveSuccess(resolve))
                    .error(() => resolveError(resolve))
            })
            .error(() => resolveError(resolve))
    })
}

/**
 * Check if the authentication tokien is expired and update it ONLY IF IT IS NEEDED.
 *
 * IT IS NEEDED WHEN:
 *   The token is expired OR it is going to be expired in less time than provided minValidity.
 *
 * @param minValidity in seconds (@default process.env.REACT_APP_KEYCLOAK_MIN_VALIDITY).
 */
export async function UpdateToken(minValidity = 5): Promise<{ keycloak: any, authenticated: boolean }> {
    return new Promise((resolve) => {
        keycloak
            .updateToken(minValidity) // Min validitiy in seconds
            .success(() => {
                (window as any).keycloak = keycloak
                resolve({ keycloak, authenticated: true })
            })
            .error(() => {
                resolve({ keycloak, authenticated: false })
            })
    })
}

export function SetRedirectUri(uri: string | null | undefined) {
    if (uri) {
        redirectUri = uri
    }
}

export async function RedirectLogout(uri: string) {
    window.location.href = uri
}

/**
 * Run the Logout function of the previously made keycloak object.
 */
export async function Logout() {
    localStorage.clear()
    keycloak.logout({ redirectUri })
}

/**
 * Create reset password link create account url.
 */
export async function CreateResetPasswordUrl() {
    return keycloak.createAccountUrl()
        .split('?')
        .join('/password?') + '&return_app=myfelfel'
}

/**
 * Return the Parsed id token which contains the users information.
 * This will structured as defined in mapper configurations of Keycloak's clients.
 */
export function IdTokenParsed() {
    return keycloak.idTokenParsed
}

export function UserRoles(): UserRolesT[] {
    return keycloak.realmAccess.roles
}

export function ApplicationUserId() {
    return IdTokenParsed().sub
}

export function HasRole(role: UserRolesT): boolean {
    return UserRoles().includes(role)
}

export function IsAdmin(): boolean {
    return ChooseByStakeholderRole([true, true, true], false)
}

export function IsFelfelian(): boolean {
    return keycloak.idTokenParsed.email.endsWith('@felfel.ch')
}

/**
 * Select a value based on stakeholder role.
 * @param values [SuperAdmin, Level 1, Level 2]
 * @returns 
 */
export function ChooseByStakeholderRole<T>(
    values: T[],
    defaultValue: T,
) {
    switch (WhichStakeholder(UserRoles())) {
        case 'super':
            return values[0]
        case 'level1':
            return values[1]
        case 'level2':
            return values[2]
        default:
            return defaultValue
    }
}
/**
 * Return currency depending on iss domain
 */
export function CurrencyFromAuth() {
    if (keycloak.idTokenParsed.iss.includes('.ch')) return 'CHF'
    return '$'
}
/**
 * Return memoized CurrencyFromAuth to use in a React Component or Hook
 */
export function useCurrency() {
    return useMemo(CurrencyFromAuth, [])
}
/**
 * Return boolean depending on iss domain
 */
export function IsSwiss() {
    if (keycloak.idTokenParsed.iss.includes('.ch')) return true
    return false
}
/**
 * Return memoized IsSwiss to use in a React Component or Hook
 */
export function useIsSwiss() {
    return useMemo(IsSwiss, [])
}

