/**
 * Determines which level of admin access user  has
 * @param roles keycloak token.realmAccess.roles
 * @returns level
 */
export default function WhichStakeholder(roles: string[]): 'level1'|'level2'|'super'|'none' {
    if(roles.includes('StakeholderUser')) {
        if(roles.includes('StakeholderLevel1User')) {
            return 'level1'
        } else if(roles.includes('StakeholderLevel2User')) {
            return 'level2'
        }
        return 'super'
    } else {
        return 'none'
    }
}