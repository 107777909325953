import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'intersection-observer/intersection-observer.js'

// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'

import { IdTokenParsed, Login, Logout } from './app/utils/keycloak-authentication'
import FA from './app/utils/start-firebase'
import Sleep from './app/utils/sleep'

// Sentry.init({
//     dsn: 'https://711a957b695e40ca820211dad449bb4b@o37218.ingest.sentry.io/6046883',
//     integrations: [new Integrations.BrowserTracing()],
//     environment: process.env.REACT_APP_SENTRY_ENV,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.5,
// });

/**
 * This iife will try to do keycloak authentication ONLY IF USER IS AUTHENTICATED:
 * It will load the LaunchApp module and execute the default function.
 * @see LaunchApp
 */
(async () => {
    console.table?.(process.env)

    try {
        const { authenticated } = await Login()

        if (authenticated) {
            const { sub, email } = IdTokenParsed()

            FA.setUserId(sub)
            FA.setUserProperties({ email })

            // To display loading animation
            const video = document.getElementById('loading-video') as HTMLVideoElement
            video.classList.add('isActive')
            video.play()

            const delay = video.currentTime < video.duration ? 1200 : 0
            Promise.all([import('./app/scripts/launch-app'), Sleep(video.duration - video.currentTime + delay)]).then(([launchAppModule]) => {
                launchAppModule.default()
            })
        } else {
            console.log('This is unexpected! User is not authenticated')
            Logout()
        }
    } catch (e) {
        console.error(e)
    }
})()
